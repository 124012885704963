body {
    overflow-x: hidden;
    margin: 0;
}

.infographic {
    margin: 0;
    width: calc(100vh * 1.35);
    max-width: 100vw;
}

.main {
    display: flex;
    justify-content: center;
}

.arrow-left {
    left: 50px;
    bottom: 50px;
}

.arrow-right {
    right: 50px;
    bottom: 50px;
}

@media (max-aspect-ratio: 13/10) {
    .arrow {
        display: none;
    }
}

@media (min-aspect-ratio: 13/10) {
    .arrow {
        position: fixed;
        height: 0;
        width: 0;
        border: 50px solid;
        border-color: rgba(0, 0, 0, 15%) rgba(0, 0, 0, 15%) transparent transparent;
        transform: rotate(135deg);
        animation-name: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    .arrow:hover {
        border-color: rgba(182, 170, 249, 75%) rgba(182, 170, 249, 75%) transparent transparent;
        animation: none;
    }


    @keyframes pulse {
        0% {
            border-color: rgba(182, 170, 249, 15%) rgba(182, 170, 249, 15%) transparent transparent;
        }
        50% {
            border-color: rgba(182, 170, 249, 30%) rgba(182, 170, 249, 30%) transparent transparent;
        }
        100% {
            border-color: rgba(182, 170, 249, 15%) rgba(182, 170, 249, 15%) transparent transparent;
        }
    }
}

